import * as React from "react";

const Disclosure = ({ children, summary }) => (
    <details className="paragraph">
        <summary style={{fontSize: '1.125em', fontWeight: 700}}>{summary}</summary>
        <div>{children}</div>
    </details>
);

export default Disclosure;
